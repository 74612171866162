import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react';
import { Grid } from 'antd';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import indexStyles from '../index.less';
import { localeSelector } from 'src/selector/app';
import { JoinClassName } from 'src/utils/utils';
import Image from 'next/image';
import CommonSection from '../CommonSection';
import { POS_WEB_SITE_CONTEXT_PATH } from 'src/consts/resource';
import RightProductsForSpecialNeedsContent from 'src/consts/poswebsite_content/right_products_for_spec_needs';
import useLocale from 'src/hooks/useLocale';
import UnoptimizedImage from 'src/components/UnoptimizedImage';

const { useBreakpoint } = Grid;
const CONTEXT_PATH = POS_WEB_SITE_CONTEXT_PATH;
const RightProductsForSpecialNeeds = () => {
  const screens = useBreakpoint();
  const local = useLocale();
  let [activeIndex, setActiveIndex] = useState(0);
  let [autoTrigger, setAutoTrigger] = useState(true);
  let [containerHeight, setContainerHeight] = useState('auto');
  let timerRef = useRef(null);
  const leftItemListRef = useRef(null);
  let onMouseEnter = useCallback((event) => {
    let activeIndex = event.currentTarget.getAttribute('data-index');
    setActiveIndex(Number.parseInt(activeIndex));
    timerRef.current && clearTimeout(timerRef.current);
    setAutoTrigger(false);
  }, []);

  let onMouseLeave = useCallback((event) => {
    // setActiveIndex(-1);
    setAutoTrigger(true);
  }, []);

  useEffect(() => {
    if (leftItemListRef.current) {
      let { height } = leftItemListRef.current.getBoundingClientRect();
      setContainerHeight(height);
    }
  }, [screens]);

  if (screens.xs) {
    return <RightProductsForSpecialNeedsForMobile />;
  }

  return (
    <CommonSection
      title={RightProductsForSpecialNeedsContent.title[local]}
      className={indexStyles.max1440Width}
      outerClassName={JoinClassName(indexStyles.whiteBackgroundcolor)}
    >
      <div className={JoinClassName(indexStyles.rightProductsForSpecialNeeds)}>
        <div className={JoinClassName(indexStyles.leftItemList)} ref={leftItemListRef}>
          {RightProductsForSpecialNeedsContent.items.map(({ title, desc, showImage, link }, index) => {
            let local_title = title[local];
            let local_desc = desc[local];
            return (
              <Link href={link} key={index}>
                <a>
                  <div
                    className={JoinClassName(indexStyles.productItem, activeIndex === index ? indexStyles.active : '')}
                    data-index={index}
                    key={index}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                  >
                    <span className={JoinClassName(indexStyles.productTitle)}>{local_title}</span>
                    <p className={JoinClassName(indexStyles.productDesc)}>{local_desc}</p>
                  </div>
                </a>
              </Link>
            );
          })}
        </div>
        <div className={JoinClassName(indexStyles.rightImageContainer)} style={{ height: containerHeight }}>
          {/* <FrozenRatio ratio={0.7}> */}
          {RightProductsForSpecialNeedsContent.items.map(({ title, desc, showImage, link }, index) => {
            let local_title = title[local];
            let imagesrc = showImage[0];
            let isActived = activeIndex === index;
            return (
              <div
                className={JoinClassName(
                  indexStyles.productItemImage,
                  isActived ? indexStyles.productItemImageActive : ''
                )}
                key={index}
              >
                <UnoptimizedImage src={imagesrc} layout="fill" objectFit="cover" alt={local_title} />
              </div>
            );
          })}
          {/* </FrozenRatio> */}
        </div>
      </div>
    </CommonSection>
  );
};

const RightProductsForSpecialNeedsForMobile = () => {
  const screens = useBreakpoint();
  const local = useLocale();

  return (
    <CommonSection
      title={RightProductsForSpecialNeedsContent.title[local]}
      className={indexStyles.rightProductsForSpecialNeedsSection}
      outerClassName={JoinClassName(indexStyles.whiteBackgroundcolor)}
    >
      <div className={JoinClassName(indexStyles.rightProductsForSpecialNeeds)}>
        <div className={JoinClassName(indexStyles.leftItemList)}>
          {RightProductsForSpecialNeedsContent.items.map(({ title, desc, showImage, link }, index) => {
            let local_title = title[local];
            let local_desc = desc[local];
            return (
              <Link href={link} key={index}>
                <a>
                  <div className={JoinClassName(indexStyles.productItem)} data-index={index} key={index}>
                    <span className={JoinClassName(indexStyles.productTitle)}>{local_title}</span>
                    <p className={JoinClassName(indexStyles.productDesc)}>{local_desc}</p>
                  </div>
                </a>
              </Link>
            );
          })}
        </div>
      </div>
    </CommonSection>
  );
};

export default RightProductsForSpecialNeeds;
