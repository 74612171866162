import React, { useMemo } from 'react';
import { Grid } from 'antd';
import indexStyles from '../index.less';
import FrozenRatio from 'src/components/FrozenRatio/FrozenRatio';
import { JoinClassName } from 'src/utils/utils';
import CommonSection from '../CommonSection';
import { useIntl } from 'react-intl';
import UnoptimizedImage from 'src/components/UnoptimizedImage';
const { useBreakpoint } = Grid;
import { LogosOnBrandWall } from 'src/consts/poswebsite_content/home';

const OurCustomerBrandWall = () => {
  const intl = useIntl();
  const screens = useBreakpoint();

  const LogosOnBrandWallGroups = useMemo(() => {
    let i = 0;
    let resultGroup = [];
    while (i < LogosOnBrandWall.length) {
      resultGroup.push(LogosOnBrandWall.slice(i, i + 18));
      i = i + 18;
    }
    let lastOffset = 18 - resultGroup[resultGroup.length - 1].length;
    if (lastOffset > 0) {
      resultGroup[resultGroup.length - 1].push(...LogosOnBrandWall.slice(0, lastOffset));
    }
    //copy to infinite scroll
    resultGroup.forEach((item) => {
      item.push(...item);
    });
    return resultGroup;
  }, [LogosOnBrandWall]);

  return (
    <CommonSection
      title={intl.formatMessage({ id: 'home.chowbuspos.trusted_by_top_restaurants' })}
      className={JoinClassName(indexStyles.max1440WidthFull, indexStyles.ourCustomerBrandWallSection)}
      outerClassName={JoinClassName(indexStyles.whiteBackgroundcolor)}
    >
      <div className={JoinClassName(indexStyles.ourCustomerBrandWall, screens.xs ? indexStyles.mobile : '')}>
        {LogosOnBrandWallGroups.map((itemGroup, rowIndex) => {
          let _logosOnBrandWall_ = itemGroup;
          let rtlFlag = rowIndex % 2 == 0;
          return (
            <div className={JoinClassName(indexStyles.scrollRowWrapper)} key={rowIndex}>
              <div
                className={JoinClassName(indexStyles.scrollRowContainer)}
                style={rtlFlag ? { animationDirection: 'reverse' } : {}}
              >
                {_logosOnBrandWall_.map((logoName, index) => {
                  let logo = `${process.env.ASSETS_PREFIX}/demo-new-logs/${logoName}`;
                  let alt = logoName;
                  return (
                    <div key={index} className={JoinClassName(indexStyles.columnItem)}>
                      <FrozenRatio ratio={1}>
                        <div className={JoinClassName(indexStyles.customerBrandContainer)}>
                          <UnoptimizedImage
                            src={logo}
                            alt={alt}
                            layout="fill"
                            objectFit="contain"
                            style={{ objectFit: 'contain', maxWidth: `100%`, maxHeight: '100%' }}
                          />
                        </div>
                      </FrozenRatio>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </CommonSection>
  );
};

export default OurCustomerBrandWall;
