import { POS_WEB_SITE_CONTEXT_PATH } from 'src/consts/resource';

const CONTEXT_PATH = POS_WEB_SITE_CONTEXT_PATH;

const SectionContent = {
  title: { zh: '定制化方案满足不同餐厅的需求', en: 'The right product for your specific needs' },
  desc: { zh: '', en: '' },
  items: [
    {
      title: { zh: '全服务', en: 'Full Service' },
      desc: {
        zh: `将您的餐厅服务提高到一个新的高度`,
        en: 'Take your tableside service to the next level！',
      },
      showImage: [`${process.env.ASSETS_PREFIX}/home/products/friends-having-lunch-at-full-service-restaurant.jpg`],
      link: `${CONTEXT_PATH}/business-types/full-service`,
    },
    {
      title: { zh: '快捷服务', en: 'Quick Service' },
      desc: {
        zh: `加速服务效率，增加餐厅营业额`,
        en: 'Accelerate service, accelerate your business',
      },
      showImage: [`${process.env.ASSETS_PREFIX}/home/products/woman-eating-burrito-at-quick-service-restaurant.jpg`],
      link: `${CONTEXT_PATH}/business-types/quick-service`,
    },
    {
      title: { zh: '火锅', en: 'Hotpot' },
      desc: {
        zh: `使用平板/扫码点餐实现顾客自助点餐的同时，还解决了火锅店菜品繁多、点单复杂，漏单/错单的痛点`,
        en: 'Chowbus POS makes hot pot simple and more profitable to serve!',
      },
      showImage: [`${process.env.ASSETS_PREFIX}/home/products/ordering-food-with-pos-tablet-at-table.jpg`],
      link: `${CONTEXT_PATH}/business-types/hot-pot`,
    },
    {
      title: { zh: '早茶店/粤菜馆', en: 'Dim Sum' },
      desc: {
        zh: `简化早茶店的服务流程，为餐厅节约人工并给顾客提供更快速的用餐体验`,
        en: 'The Industry’s top POS for Dim Sum',
      },
      showImage: [`${process.env.ASSETS_PREFIX}/home/products/dim-sum-pan-fried-shrimp-dumplings.jpg`],
      link: `${CONTEXT_PATH}/business-types/dim-sum-pos`,
    },
    {
      title: { zh: '韩国烤肉', en: 'Korean BBQ' },
      desc: {
        zh: `顾客使用平板/扫码点餐，下单后订单一键送厨。服务员可以专注于批量上菜和服务顾客，无需多次往返于顾客和POS机之间，大大节省时间、提升效率`,
        en: 'Fire up the guest experience, boost your profits.',
      },
      showImage: [`${process.env.ASSETS_PREFIX}/home/products/korean-bbq-handheld-pos-payment.jpg`],
      link: `${CONTEXT_PATH}/business-types/korean-bbq`,
    },
    {
      title: { zh: '奶茶店 & 甜点店', en: 'Boba Tea & Dessert' },
      desc: {
        zh: `顾客使用大屏点餐机挑选、下单产品，商家仅需专注于餐品制作，在提升出餐效率的同时，降低人力成本`,
        en: 'The best POS for Boba in the market',
      },
      showImage: [`${process.env.ASSETS_PREFIX}/home/products/customers-ordering-from-boba-shop-kiosk-pos.jpeg`],
      link: `${CONTEXT_PATH}/business-types/boba-tea-dessert`,
    },

    {
      title: { zh: '日料&拉面店', en: 'Sushi & Ramen' },
      desc: {
        zh: '无论是堂食还是外卖订单，均可通过扫码点单和第三方外卖平台集合服务直接送入后厨，大大提升效率、降低人力成本。更有网页订餐和会员系统助您提升营业额和顾客复购率',
        en: 'Harmonize the front-of-house, guest experience, and kitchen.',
      },
      showImage: [`${process.env.ASSETS_PREFIX}/home/products/japanese-food-ramen-gyoza.jpg`],
      link: `${CONTEXT_PATH}/business-types/sushi-ramen`,
    },
    {
      title: { zh: '咖啡厅', en: 'Cafes' },
      desc: {
        zh: '最佳咖啡伴侣，伴您轻松度过忙碌的早晨',
        en: 'Serve up the morning fix with an intuitive, easy-to-use POS – now with flexible payment options.',
      },
      showImage: [`${process.env.ASSETS_PREFIX}/home/products/smiling-barista-making-an-order.jpg`],
      link: `${CONTEXT_PATH}/business-types/cafes`,
    },
  ],
};

export default SectionContent;
