import React, { useEffect, useState, useMemo } from 'react';
import { Grid, Carousel } from 'antd';
import Link from 'next/link';
import { localeSelector } from 'src/selector/app';
import { useSelector } from 'react-redux';
import indexStyles from '../index.less';
import FrozenRatio from 'src/components/FrozenRatio/FrozenRatio';
import { JoinClassName } from 'src/utils/utils';
import Image from 'next/image';
import CommonSection from '../CommonSection';

import { POS_WEB_SITE_CONTEXT_PATH } from 'src/consts/resource';
import PosProductsPageContent from 'src/consts/poswebsite_content/everythingNeedInOnePOSSystem';
import useLocale from 'src/hooks/useLocale';
import UnoptimizedImage from 'src/components/UnoptimizedImage';

const { useBreakpoint } = Grid;
const CONTEXT_PATH = POS_WEB_SITE_CONTEXT_PATH;
const slidesConfigs = [
  {
    size: 'xxl',
    count: 6,
  },
  {
    size: 'xl',
    count: 5,
  },
  {
    size: 'lg',
    count: 4,
  },
  {
    size: 'md',
    count: 3,
  },
  {
    size: 'sm',
    count: 2,
  },
  {
    size: 'xs',
    count: 3,
  },
];
const EverythingNeedInOnePOSSystem = ({ title, productsNeed }) => {
  const locale = useLocale();
  const screens = useBreakpoint();
  const isMobile = screens.xs;
  const [slidesToShow, setSlidesToShow] = useState(5);
  useEffect(() => {
    let matched = slidesConfigs.find(({ size }) => screens[size]);
    if (matched) {
      setSlidesToShow(matched.count);
    }
  }, [screens]);

  const everythingNeedInOnePOSSystemContent = useMemo(() => {
    let { title, desc, products } = PosProductsPageContent[locale];
    if (!productsNeed) {
      return {
        title,
        desc: desc || '',
        products,
      };
    }
    return {
      title,
      desc: desc || '',
      products: (productsNeed || []).map((productKey) => {
        return products.find((someProduct) => {
          return someProduct.key === productKey;
        });
      }),
    };
  }, [locale, productsNeed]);

  if (screens.xs) {
    return (
      <EverythingNeedInOnePOSSystemForMobile
        productsNeed={productsNeed}
        title={title || everythingNeedInOnePOSSystemContent.title}
      />
    );
  }
  return (
    <CommonSection
      title={title || everythingNeedInOnePOSSystemContent.title}
      desc={everythingNeedInOnePOSSystemContent.desc || ''}
      // className={indexStyles.max1440Width}
      outerClassName={JoinClassName(indexStyles.lightGrayBackgroundcolor)}
      className={indexStyles.everythingNeedInOnePOSSystemSection}
    >
      <div className={JoinClassName(indexStyles.productsGallery)}>
        <div className={JoinClassName(indexStyles.productsGalleryWrapper)}>
          <Carousel
            autoplay={true}
            autoplaySpeed={5000}
            pauseOnDotsHover={true}
            slidesToShow={slidesToShow}
            slidesToScroll={1}
            dots={false}
            arrows={true}
            infinite={true}
          >
            {everythingNeedInOnePOSSystemContent.products.map(({ coverImage, name, desc, link, key }, index) => {
              return (
                <div className={JoinClassName(indexStyles.productItemCardWrapper)} key={key}>
                  <Link href={link} alt={name}>
                    <a>
                      <div className={JoinClassName(indexStyles.productItemCard)}>
                        <div style={{ flex: 280 }}>
                          <FrozenRatio ratio={1}>
                            <UnoptimizedImage src={coverImage} layout="fill" objectFit="cover" alt={name} />
                          </FrozenRatio>
                        </div>
                        <div style={{ flex: 160 }}>
                          <FrozenRatio ratio={160 / 280}>
                            <div>
                              {/* <span className={JoinClassName(indexStyles.productItemTitle)}>{name}</span> */}
                              <h3 className={JoinClassName(indexStyles.productItemTitle)}>{name}</h3>
                              <span className={JoinClassName(indexStyles.productDesc)}>{desc}</span>
                            </div>
                          </FrozenRatio>
                        </div>
                      </div>
                    </a>
                  </Link>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </CommonSection>
  );
};

const EverythingNeedInOnePOSSystemForMobile = ({ title, productsNeed }) => {
  const screens = useBreakpoint();
  const locale = useSelector(localeSelector);
  const isMobile = screens.xs;
  const [slidesToShow, setSlidesToShow] = useState(5);
  useEffect(() => {
    if (screens.xxl) {
      // 屏幕 ≥ 1600px
      //1600
      setSlidesToShow(6);
    } else if (screens.xl) {
      //屏幕 ≥ 1200px
      //1200
      setSlidesToShow(5);
    } else if (screens.lg) {
      //992
      //屏幕 ≥ 992px
      setSlidesToShow(4);
    } else if (screens.xs) {
      //xs	屏幕 < 576px 响应式栅格
      //576
      setSlidesToShow(3);
    }
    //sm	屏幕 ≥ 576px
    //md 屏幕 ≥ 768px
  }, [screens]);

  const everythingNeedInOnePOSSystemContent = useMemo(() => {
    let { title, products } = PosProductsPageContent[locale];
    if (!productsNeed) {
      return {
        title,
        products,
      };
    }
    return {
      title,
      products: (productsNeed || []).map((productKey) => {
        return products.find((someProduct) => {
          return someProduct.key === productKey;
        });
      }),
    };
  }, [locale, productsNeed]);

  return (
    <CommonSection
      title={title || everythingNeedInOnePOSSystemContent.title}
      outerClassName={JoinClassName(indexStyles.whiteBackgroundcolor)}
      className={indexStyles.everythingNeedInOnePOSSystemSection}
    >
      <div className={JoinClassName(indexStyles.productsGallery)}>
        {everythingNeedInOnePOSSystemContent.products.map(({ coverImage, name, desc, link }, index) => {
          return (
            <Link href={link} alt={name} key={index}>
              <a style={{ width: '100%' }}>
                <div className={JoinClassName(indexStyles.productItemCard)}>
                  <h3 className={JoinClassName(indexStyles.productItemTitle)}>{name}</h3>
                </div>
              </a>
            </Link>
          );
        })}
      </div>
    </CommonSection>
  );
};
export default EverythingNeedInOnePOSSystem;
