import { POS_WEB_SITE_CONTEXT_PATH } from 'src/consts/resource';
const CONTEXT_PATH = POS_WEB_SITE_CONTEXT_PATH;
const PRODUCT_ONLINE_ORDERING = 'Online Ordering';
const PRODUCT_TABLET_ORDERING = 'Tablet Ordering';
const PRODUCT_POINT_OF_SALE = 'Point of Sale';
const PRODUCT_KIOSK = 'Kiosk';
const PRODUCT_POS_HANDHELD = 'POS Handheld';
const PRODUCT_QRCODE_ORDERING = 'QR Code Ordering';
const PRODUCT_LOYALTY = 'Restaurant Loyalty';
const PRODUCT_WAITLIST = 'Restaurant Waitlist';
const PRODUCT_RESERVATION = 'Restaurant Reservation';
const PRODUCT_THIRD_PARTY_INTEGRATION = '3rd Party Integration';
const PRODUCT_RUTECH_ROBOT = 'Rutech Robot';

const gallery_3rd_integration = `${process.env.ASSETS_PREFIX}/assets/gallery_3rd_integration.jpeg`;
const gallery_kiosk = `${process.env.ASSETS_PREFIX}/assets/gallery_customers-ordering-from-boba-shop-kiosk-pos.jpeg`;
const gallery_onlineordering = `${process.env.ASSETS_PREFIX}/assets/gallery_onlineordering.jpeg`;
const gallery_pointofsale = `${process.env.ASSETS_PREFIX}/assets/gallery_pointofsale.jpeg`;
const gallery_poshandled = `${process.env.ASSETS_PREFIX}/assets/gallery_customer-paying-on-pos-handheld.jpg`;
const gallery_qrcodeordering = `${process.env.ASSETS_PREFIX}/assets/gallery_qrcodeordering.jpeg`;
const gallery_tabletordering = `${process.env.ASSETS_PREFIX}/assets/gallery_ordering-food-with-pos-tablet-at-table.jpg`;
const gallery_waitlist = `${process.env.ASSETS_PREFIX}/assets/gallery_waitlist.jpeg`;
const gallery_loyalty = `${process.env.ASSETS_PREFIX}/assets/gallery_loyalty.jpeg`;
const gallery_robots = `${process.env.ASSETS_PREFIX}/assets/gallery_robots.jpeg`;
const gallery_reservation = `${process.env.ASSETS_PREFIX}/assets/gallery_reservation.jpg`;

const SectionContent = {
  en: {
    title: 'The Ultimate All-in-one restaurant POS system',
    desc: 'Everything you need in one POS system',
    products: [
      {
        coverImage: gallery_onlineordering,
        name: 'Online Ordering',
        key: PRODUCT_ONLINE_ORDERING,
        desc: 'Take orders from your own website, the most profitable order source.',
        link: `${CONTEXT_PATH}/products/online-ordering`,
      },
      {
        coverImage: gallery_tabletordering,
        name: 'Tablet Ordering',
        desc: `Chowbus tablet ordering provides the ultimate ordering solution to elevate your diner and waitstaff's
                  experience.`,
        link: `${CONTEXT_PATH}/products/tablet-ordering`,
        key: PRODUCT_TABLET_ORDERING,
      },
      {
        coverImage: gallery_pointofsale,
        name: 'Point of Sale',
        desc: ` The next-generation all-in-one POS that can save you >$8,000/mo in labor costs and grow your business
                  by 25%.`,
        link: `${CONTEXT_PATH}/products/point-of-sale`,
        key: PRODUCT_POINT_OF_SALE,
      },
      {
        coverImage: gallery_kiosk,
        name: 'Kiosk',
        desc: ` Designed to empower your guests and your staff with an effective and efficient digital ordering
                  experience.`,
        link: `${CONTEXT_PATH}/products/kiosk-ordering`,
        key: PRODUCT_KIOSK,
      },
      {
        coverImage: gallery_poshandled,
        name: 'POS Handheld',
        desc: ` A powerful and portable device allowing you to take payments anytime, anywhere.`,
        link: `${CONTEXT_PATH}/products/handheld`,
        key: PRODUCT_POS_HANDHELD,
      },
      {
        coverImage: gallery_qrcodeordering,
        name: 'QR Code Ordering',
        desc: `Designed to empower your guests and your staff with a smart contactless ordering experience.`,
        link: `${CONTEXT_PATH}/products/qrcode-ordering`,
        key: PRODUCT_QRCODE_ORDERING,
      },
      {
        coverImage: gallery_loyalty,
        name: 'Restaurant Loyalty',
        desc: `Say goodbye to paper loyalty cards with Chowbus' exclusive loyalty program and increase your business
                  volume by 25%!`,
        link: `${CONTEXT_PATH}/products/loyalty`,
        key: PRODUCT_LOYALTY,
      },
      {
        coverImage: gallery_waitlist,
        name: 'Restaurant Waitlist',
        desc: `Chowbus Waitlist allows your guests to book a table at your restaurant anytime, anywhere.`,
        link: `${CONTEXT_PATH}/products/waitlist`,
        key: PRODUCT_WAITLIST,
      },
      {
        coverImage: gallery_reservation,
        name: 'Restaurant Reservations',
        desc: `Simplify table reservations and delight customers.`,
        link: `${CONTEXT_PATH}/products/restaurant-reservation-app`,
        key: PRODUCT_RESERVATION,
      },

      {
        coverImage: gallery_3rd_integration,
        name: '3rd Party Integration',
        desc: `Chowbus POS provides third party delivery integration with over 45 delivery providers including all
                  major marketplace partners.`,
        link: `${CONTEXT_PATH}/products/thirdparty-integration`,
        key: PRODUCT_THIRD_PARTY_INTEGRATION,
      },
      {
        coverImage: gallery_robots,
        name: 'Rutech Robot',
        desc: ` As the Exclusive distributor and technical service partner of Pudu Technology in North America`,
        // link: `${CONTEXT_PATH}/products/rutech-robot`,
        link: `${CONTEXT_PATH}/products/rutech-robot`,
        key: PRODUCT_RUTECH_ROBOT,
      },
    ],
  },
  zh: {
    title: '餐馆电脑｜点餐系统｜最适合您的餐馆电脑产品组合',
    desc: '为您提供一站式解决方案',
    products: [
      {
        coverImage: gallery_onlineordering,
        name: '餐厅订餐网页',
        desc: '搭建自己的餐厅订餐网页，增加新的客单来源',
        link: `${CONTEXT_PATH}/products/online-ordering`,
        key: PRODUCT_ONLINE_ORDERING,
      },
      {
        coverImage: gallery_tabletordering,
        name: '平板点单',
        desc: `提升顾客点餐体验的同时，减少人力投入`,
        link: `${CONTEXT_PATH}/products/tablet-ordering`,
        key: PRODUCT_TABLET_ORDERING,
      },
      {
        coverImage: gallery_pointofsale,
        name: 'POS',
        desc: `专为亚洲餐厅打造的餐饮管理系统，实现降本增效`,
        link: `${CONTEXT_PATH}/products/point-of-sale`,
        key: PRODUCT_POINT_OF_SALE,
      },
      {
        coverImage: gallery_kiosk,
        name: '智能自助点餐机',
        desc: `在减少人工的同时，还能通过吸睛大图帮助客户了解商品、下单心仪餐品，提升点单效率`,
        link: `${CONTEXT_PATH}/products/kiosk-ordering`,
        key: PRODUCT_KIOSK,
      },
      {
        coverImage: gallery_poshandled,
        name: '手持POS',
        desc: `功能全面且便携的移动支付终端，支持刷卡且存储电子签名，提升收款效率`,
        link: `${CONTEXT_PATH}/products/handheld`,
        key: PRODUCT_POS_HANDHELD,
      },
      {
        coverImage: gallery_qrcodeordering,
        name: '扫码点单',
        desc: `便捷点餐体验，多人点餐也可轻松同步`,
        link: `${CONTEXT_PATH}/products/qrcode-ordering`,
        key: PRODUCT_QRCODE_ORDERING,
      },
      {
        coverImage: gallery_loyalty,
        name: '会员系统',
        desc: `轻松搭建专属会员系统，提升顾客黏性和复购率`,
        link: `${CONTEXT_PATH}/products/loyalty`,
        key: PRODUCT_LOYALTY,
      },
      {
        coverImage: gallery_waitlist,
        name: '等位系统',
        desc: `让您的顾客随时随地预约、等位，综合提升用餐体验`,
        link: `${CONTEXT_PATH}/products/waitlist`,
        key: PRODUCT_WAITLIST,
      },
      {
        coverImage: gallery_reservation,
        name: '预约',
        desc: `和POS系统打通的预约功能，覆盖多渠道.`,
        link: `${CONTEXT_PATH}/products/restaurant-reservation-app`,
        key: PRODUCT_RESERVATION,
      },

      {
        coverImage: gallery_3rd_integration,
        name: '第三方平台集成',
        desc: `提供近50个外卖平台的第三方集成服务，轻松统一管理菜单、菜品价格和营业额`,
        link: `${CONTEXT_PATH}/products/thirdparty-integration`,
        key: PRODUCT_THIRD_PARTY_INTEGRATION,
      },
      {
        coverImage: gallery_robots,
        name: 'Rutech Robot',
        desc: `普度科技北美最大的分销商和技术合作伙伴`,
        // link: `${CONTEXT_PATH}/products/rutech-robot`,
        link: `${CONTEXT_PATH}/products/rutech-robot`,
        key: PRODUCT_RUTECH_ROBOT,
      },
    ],
  },
};
export default SectionContent;

export const PRODUCTS = {
  PRODUCT_ONLINE_ORDERING,
  PRODUCT_TABLET_ORDERING,
  PRODUCT_POINT_OF_SALE,
  PRODUCT_KIOSK,
  PRODUCT_POS_HANDHELD,
  PRODUCT_QRCODE_ORDERING,
  PRODUCT_LOYALTY,
  PRODUCT_WAITLIST,
  PRODUCT_THIRD_PARTY_INTEGRATION,
  PRODUCT_RUTECH_ROBOT,
};
