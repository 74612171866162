import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { Row, Col, Button, Grid } from 'antd';
import Link from 'next/link';
import indexStyles from '../index.less';
import FrozenRatio from 'src/components/FrozenRatio/FrozenRatio';
import { JoinClassName } from 'src/utils/utils';
import { FormattedMessage } from 'react-intl';
import HomePageContent from 'src/consts/poswebsite_content/home';
import useLocale from 'src/hooks/useLocale';
import UnoptimizedImage from 'src/components/UnoptimizedImage';

const { useBreakpoint } = Grid;
const playVideo32Icon = `${process.env.ASSETS_PREFIX}/assets/play_video_button_32.png`;
const HomeTopSection = () => {
  const locale = useLocale();
  const screens = useBreakpoint();
  const isPCScreen = screens.xl;
  const [playYTVideoRequest, setPlayYTVideoRequest] = useState(false);
  const videoPlayerRef = useRef(null);

  const onPlayYTVideo = useCallback(() => {
    setPlayYTVideoRequest(true);
  }, []);

  const topSection = useMemo(() => {
    return HomePageContent.topSection[locale];
  }, [locale]);

  useEffect(() => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.pause();
      videoPlayerRef.current.currentTime = 0;
      videoPlayerRef.current.src = topSection.videoUrl;
    }
  }, [locale, topSection]);

  if (screens.xs) {
    return <HomeTopSectionMobile />;
  }

  return (
    <React.Fragment>
      <div
        className={JoinClassName(
          indexStyles.topSectionWrapper,
          screens.xs ? indexStyles.forMobile : '',
          screens.md && !screens.lg ? indexStyles.forMDDevice : '', //768
          screens.sm && !screens.md ? indexStyles.forSMDevice : '' //576
        )}
      >
        <Row align={'middle'} justify={'center'} wrap={false}>
          <Col xs={24}>
            <div className={JoinClassName(indexStyles.topSection)}>
              {!playYTVideoRequest && (
                <div className={JoinClassName(indexStyles.backgroundVideo)}>
                  <video autoPlay playsInline loop muted>
                    <source src={`${process.env.ASSETS_PREFIX}/chowbus_pos_mini.mp4`} type="video/mp4" />
                    <p>
                      Your browser doesn't support HTML video. Here is a
                      <a href={`${process.env.ASSETS_PREFIX}/chowbus_pos_mini.mp4`}>link to the video</a> instead.
                    </p>
                  </video>
                </div>
              )}

              <div className={JoinClassName(indexStyles.topSectionMainContainer)}>
                <FrozenRatio ratio={600 / 1440}>
                  <div className={JoinClassName(indexStyles.topSectionMain)} style={{ overflow: 'hidden' }}>
                    {playYTVideoRequest && (
                      <div style={{ height: '600px', width: (16 * 600) / 9 + 'px' }}>
                        <video
                          poster={`${process.env.ASSETS_PREFIX}/home/home_top_video_cover.jpg`}
                          autoPlay
                          playsInline
                          loop
                          controls
                          style={{ width: '100%', height: '100%' }}
                          ref={videoPlayerRef}
                        >
                          <source src={`${process.env.ASSETS_PREFIX}/${topSection.videoUrl}`} type="video/mp4" />
                          <p>
                            Your browser doesn't support HTML video. Here is a
                            <a href={`${process.env.ASSETS_PREFIX}/${topSection.videoUrl}`}>link to the video</a>{' '}
                            instead.
                          </p>
                        </video>
                      </div>
                      // </FrozenRatio>
                    )}
                    {!playYTVideoRequest && (
                      <React.Fragment>
                        <h1 className="title2">{topSection.slogan}</h1>
                        <span className="subtitle">{topSection.sloganSubTitle}</span>
                        <Row
                          align={'middle'}
                          justify={'center'}
                          wrap={false}
                          style={{ marginTop: '30px' }}
                          gutter={[20]}
                        >
                          <Col>
                            <Link href="/get-a-demo?source=homepage_button">
                              <a>
                                <Button shape="round" size="large" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                  <FormattedMessage id="home.get-demo.bitton" />
                                </Button>
                              </a>
                            </Link>
                          </Col>
                          <Col>
                            <Button type="primary" shape="round" onClick={onPlayYTVideo} size="large">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  lineHeight: 'normal',
                                }}
                              >
                                <div style={{ width: '32px', height: '32px', position: 'relative' }}>
                                  <UnoptimizedImage
                                    src={playVideo32Icon}
                                    layout="fill"
                                    objectFit="contain"
                                    alt={'GO'}
                                  />
                                </div>
                                <span style={{ marginLeft: '15px' }}>
                                  {/* How Chowbus Works */}
                                  <FormattedMessage id="home.chowbuspos.seechowbusworks" />
                                </span>
                              </div>
                            </Button>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </div>
                </FrozenRatio>
              </div>
            </div>
          </Col>
        </Row>
        <Row align={'middle'} justify={'center'} wrap={false}>
          <Col xs={24} lg={24}>
            <div className={JoinClassName(indexStyles.topSectionBottom)}>
              <div className={JoinClassName(indexStyles.topSectionBottomContainer)}>
                <div className={JoinClassName(indexStyles.topSectionBottomLeft)}>
                  <h2 className={JoinClassName(indexStyles.emphasisItem, indexStyles.whyloveChowbus)}>
                    {topSection.whyLoveChowbus}
                  </h2>
                </div>
                <div className={JoinClassName(indexStyles.topSectionBottomRight)}>
                  {topSection.points.map((item, index) => {
                    let [key, desc] = item;
                    return (
                      <div className={JoinClassName(indexStyles.row)} key={index}>
                        {/* <span className={JoinClassName(indexStyles.emphasisItem)}>{key}</span> */}
                        <span className={JoinClassName(indexStyles.listItem)} style={{ lineHeight: 0 }}>
                          {desc}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

const HomeTopSectionMobile = () => {
  const locale = useLocale();
  const screens = useBreakpoint();
  const isPCScreen = screens.xl;
  let isRunInIOS = false;
  if (typeof window !== 'undefined') {
    isRunInIOS =
      window.navigator && navigator && navigator.userAgent && /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
  }

  const topSection = useMemo(() => {
    return HomePageContent.topSection[locale];
  }, [locale]);

  const [playYTVideoRequest, setPlayYTVideoRequest] = useState(false);
  const onPlayYTVideo = useCallback(() => {
    setPlayYTVideoRequest(true);
  }, []);
  const videoPlayerRef = useRef(null);

  useEffect(() => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.pause();
      videoPlayerRef.current.currentTime = 0;
      videoPlayerRef.current.src = topSection.videoUrl;
    }
  }, [locale, topSection]);

  return (
    <React.Fragment>
      <div className={JoinClassName(indexStyles.forMobile)} style={{ paddingTop: 50 }}>
        <Row align={'middle'} justify={'center'} wrap={false}>
          <Col xs={24}>
            <div className={JoinClassName(indexStyles.topSectionMain)}>
              <h1 className="title2">{topSection.slogan} </h1>
              <span className="subtitle">{topSection.sloganSubTitle}</span>
              <Link href="/get-a-demo">
                <a>
                  <Button
                    style={{ marginTop: '20px', paddingTop: 0, paddingBottom: 0 }}
                    shape="round"
                    type="primary"
                    size="large"
                  >
                    <FormattedMessage id="home.get-demo.bitton" />
                  </Button>
                </a>
              </Link>
            </div>
          </Col>
        </Row>
        <Row align={'middle'} justify={'center'} wrap={false}>
          <Col xs={24}>
            <div className={JoinClassName(indexStyles.topSection)}>
              {!isRunInIOS && (
                <div className={JoinClassName(indexStyles.backgroundVideo)}>
                  <video autoPlay playsinline loop muted>
                    <source src={`${process.env.ASSETS_PREFIX}/chowbus_pos_mini.mp4`} type="video/mp4" />
                    <p>
                      Your browser doesn't support HTML video. Here is a
                      <a href={`${process.env.ASSETS_PREFIX}/chowbus_pos_mini.mp4`}>link to the video</a> instead.
                    </p>
                  </video>
                </div>
              )}

              <div className={JoinClassName(indexStyles.topSectionMainContainer)}>
                <FrozenRatio ratio={210 / 374}>
                  <div className={JoinClassName(indexStyles.topSectionMain)}>
                    {playYTVideoRequest && (
                      // <FrozenRatio ratio={9 / 16}>
                      <div style={{ height: '100%', width: '100%' }}>
                        <video
                          poster={`${process.env.ASSETS_PREFIX}/home/home_top_video_cover.jpg`}
                          autoPlay
                          playsInline
                          loop
                          controls
                          style={{ width: '100%', height: '100%' }}
                          ref={videoPlayerRef}
                        >
                          <source src={`${process.env.ASSETS_PREFIX}/${topSection.videoUrl}`} type="video/mp4" />
                          <p>
                            Your browser doesn't support HTML video. Here is a
                            <a href={`${process.env.ASSETS_PREFIX}/${topSection.videoUrl}`}>link to the video</a>{' '}
                            instead.
                          </p>
                        </video>
                      </div>
                      // </FrozenRatio>
                    )}
                    {!playYTVideoRequest && (
                      <Row align={'middle'} justify={'center'} wrap={false} gutter={[20]}>
                        <Col>
                          <Button type="primary" shape="round" onClick={onPlayYTVideo} size="large">
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                              }}
                            >
                              <div className={JoinClassName(indexStyles.playVideo32IconContainer)}>
                                <UnoptimizedImage src={playVideo32Icon} layout="fill" objectFit="contain" alt={'GO'} />
                              </div>
                              <span style={{ marginLeft: '15px' }}>
                                <FormattedMessage id="home.chowbuspos.seechowbusworks" />
                              </span>
                            </div>
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </div>
                </FrozenRatio>
              </div>
            </div>
          </Col>
        </Row>
        <Row align={'center'} justify={'center'} wrap={false}>
          <Col xs={22} lg={24}>
            <div className={JoinClassName(indexStyles.topSectionBottom)}>
              <div className={JoinClassName(indexStyles.topSectionBottomContainer)}>
                <div className={JoinClassName(indexStyles.topSectionBottomLeft)}>
                  <h2 className={JoinClassName(indexStyles.emphasisItem, indexStyles.whyloveChowbus)}>
                    {topSection.whyLoveChowbus}
                  </h2>
                </div>
                <div className={JoinClassName(indexStyles.topSectionBottomRight)}>
                  {topSection.points.map((item, index) => {
                    const [key, desc] = item;
                    return (
                      <div className={JoinClassName(indexStyles.row)} key={index}>
                        {/* <span className={JoinClassName(indexStyles.emphasisItem)}>{key}</span> */}
                        <span className={JoinClassName(indexStyles.listItem)}>{desc}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default HomeTopSection;
